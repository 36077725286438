import styles from './styles.module.css'
import { TextInputField } from '../../entities/TextInputField'
import { SendButton } from '../../entities/SendButton'
import { type SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { type TFileStore, type TFileStoreCreationResult } from '../../pages/DocsPage/types'
import { Alert } from '@mui/material'
import { FileStoreManipulationService } from '../../shared/docs/services/file-store-manipulation-service'
export function FileStoreCreationForm () {
  const [nameFile, setNameFile] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
          setFile(e.target.files[0]); // Сохраняем выбранный файл
      }
  };

  useEffect(() => console.log(file))


  const onClickCreate = useCallback(async () => {
    if (!file) {
      console.log(file)
      console.log('No file')
      return
    };
    if (!nameFile) {
      console.log('No name File')
      return
    };

    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', nameFile);

    const fileStoreManipulationService = FileStoreManipulationService()
    const results: TFileStoreCreationResult = await fileStoreManipulationService.createFileStore(formData)

    if (results.errorMessage) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      setErrorMessage(results.errorMessage); return
    }
    setTimeout(() => { setResultMessage('') }, 5000)
    setResultMessage('Документ успешно создан')
  }, [nameFile, file])

  return <form className={styles.FileStoreCreationForm}>
      <TextInputField
        value={nameFile}
        className={styles.InputFields}
        id={'FileName'}
        label={'Название файла'}
        onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setNameFile(e.currentTarget.value) }} />
      <div className={styles.WeightHeightRow}>
        <input
          className={styles.InputFields}
          type='file'
          id={'File'}
          onChange={handleFileChange}/>
      </div>
      <div className={styles.WeightHeightRow}>
        <SendButton className={styles.InputFields} label={'Очистить'} onClick={() => {}} />
        <SendButton className={styles.InputFields} label={'Создать'} onClick={onClickCreate} />
      </div>
      <div></div>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
  </form>
}
