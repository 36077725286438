import { type TPlayer, type TPlayerCreationResponse, type TPlayerListResponse } from '../../../pages/PlayersPage/types'

const OPERATOR_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/admin`

export type TOperator = {
  login: string
  role: string
  id: string
}

export function OperatorManipulationService () {

  const takeOperatorList = async () => {
    const fetchRes = await fetch(OPERATOR_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для получения списка операторов',
        operators: null
      }
    }
    const response: TPlayerCreationResponse = await fetchRes.json()

    if (response.status === 406) {
      return {
        errorMessage: response.message || null,
        operators: null
      }
    }

    return {
      errorMessage: null,
      operators: response,
    }
  }

  const createPlayer = async (player: TPlayer) => {
    const fetchRes = await fetch(OPERATOR_URL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(player)
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        player: null
      }
    }

    const response: TPlayerCreationResponse = await fetchRes.json()

    if (response.status === 406) {
      return {
        errorMessage: response.message || null,
        player: null
      }
    }

    return {
      errorMessage: null,
      player: response.player
    }
  }

  return {
    takeOperatorList,
  }
}
