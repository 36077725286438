import { put, takeEvery, all } from 'redux-saga/effects'
import { PlayerManipulationService } from '../../../shared/player/services/player-manipulation-service'
import { TPlayer, TPlayerListResponse, type TPlayersArrayMessage } from '../../../pages/PlayersPage/types'
import { useDispatch } from 'react-redux'
import { setPlayers } from '../slices/player'
import { setMessages } from '../slices/errors'
import { store } from '../index'
import { TournamentManipulationService } from '../../../shared/tournaments/services/tournament-manipulation-service'
import { type TTournamentsArrayMessage } from '../../../pages/TournamentsPage/types'
import { setTournaments } from '../slices/tournaments'
import { setEditableTournamentFields } from '../slices/editableTournament'
import { MatchManipulationService } from '../../../shared/match/match-manipulation-service'
import { setMatches } from '../slices/match'
import { setRole } from '../slices/login'
import { OperatorManipulationService } from '../../../shared/operator/operator-manipulation-service/operator-manipulation-service'
import { setOperators } from '../slices/operators'
import { NewsManipulationService } from 'shared/news/services/news-manipulation-service'
import { TNewsArrayMessage } from 'pages/NewsPage/types'
import { setAllNews } from '../slices/news'

export const loadPlayersSaga = async () => {
  const dispatch = store.dispatch
  const playerManipulatorService = PlayerManipulationService()
  const players: TPlayersArrayMessage = await playerManipulatorService.takeWholePlayers()
  if (players.errorMessage) {
    return put(dispatch(setMessages(players.errorMessage)))
  }
  put(dispatch(setPlayers(players.players)))
}

export const loadNewsSaga = async () => {
  const dispatch = store.dispatch
  const newsManipulatorService = NewsManipulationService()
  const allNews: TNewsArrayMessage = await newsManipulatorService.takeWholeAllNews()
  if (allNews.errorMessage) {
    return put(dispatch(setMessages(allNews.errorMessage)))
  }
  put(dispatch(setAllNews(allNews.allNews)))
}

export const loadMatchesSaga = async () => {
  const dispatch = store.dispatch
  const matchManipulatorService = MatchManipulationService()
  const matches: any = await matchManipulatorService.takeWholeMaches()
  if (matches.errorMessage) {
    return put(dispatch(setMessages(matches.errorMessage)))
  }
  put(dispatch(setMatches(matches.matches)))
}

export const loadEditableTournamentFromLocalStorage = async () => {
  const dispatch = store.dispatch
  const localTournament = localStorage.getItem('editableTournament')
  if (!localTournament) {
    return
  }

  put(dispatch(setEditableTournamentFields(JSON.parse(localTournament))))
}

export const loadTournamentsSaga = async () => {
  const dispatch = store.dispatch
  const tournamentManipulationService = TournamentManipulationService()
  const tournaments: TTournamentsArrayMessage = await tournamentManipulationService.takeWholeTournaments()
  if (tournaments.errorMessage) {
    return put(dispatch(setMessages(tournaments.errorMessage)))
  }
  put(dispatch(setTournaments(tournaments.tournaments)))
}

export const loadAdminRoleSaga = async () => {
  const dispatch = store.dispatch
  const role = localStorage.getItem('role')
  dispatch(setRole(role))
}

export const loadOperatorsList = async () => {
  const dispatch = store.dispatch
  const operatorManipulationService = OperatorManipulationService()
  const {errorMessage, operators}: any = await operatorManipulationService.takeOperatorList()
  return dispatch(setOperators(operators?.reverse() || []))
}

export function * rootSaga () {
  yield all([
    loadNewsSaga(),
    loadAdminRoleSaga(),
    loadPlayersSaga(),
    loadMatchesSaga(),
    loadTournamentsSaga(),
    loadEditableTournamentFromLocalStorage(),
    loadOperatorsList(),
  ])
}
