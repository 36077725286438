import { type TFileStore, type TFileStoreCreationResponse, type TFileStoreListResponse } from '../../../pages/DocsPage/types'

const DOCS_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/filestore`

export function FileStoreManipulationService () {
  const createFileStore = async (formData: FormData) => {
    const fetchRes = await fetch(DOCS_URL, {
      method: 'post',
      body: formData
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания файла',
        fileStore: null
      }
    }

    const response: TFileStoreCreationResponse = await fetchRes.json()

    if (response.status === 406) {
      return {
        errorMessage: response.message || null,
        fileStore: null
      }
    }

    return {
      errorMessage: null,
      fileStore: response.fileStore
    }
  }

  const takeWholeFileStores = async () => {
    const fetchRes = await fetch(DOCS_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        fileStores: null
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет ни одного игрока',
        fileStores: null
      }
    }

    const response: TFileStoreListResponse = await fetchRes.json()

    return {
      errorMessage: null,
      fileStores: response.fileStores
    }
  }

  const removeFileStore = async (id: string) => {
    const fetchRes = await fetch(`${DOCS_URL}/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого игрока',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      return {
        errorMessage: 'Не прошла валидация полей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неожиданная ошибка в процессе удаления пользователя. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  const updateFileStore = async (fileStore: TFileStore) => {
    const fetchRes = await fetch(`${DOCS_URL}/${fileStore.id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...fileStore, id: undefined })
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого игрока',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      const responseJson = await fetchRes.json()
      return {
        errorMessage: 'Не прошла валидация полей: ' + responseJson.message,
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неопознанная ошибка. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  return {
    createFileStore,
    takeWholeFileStores,
    removeFileStore,
    updateFileStore
  }
}
