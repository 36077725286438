import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import {
  DataGrid
} from '../../widgets/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { NewsManipulationService } from '../../shared/news/services/news-manipulation-service'
import { type TNews, type TNewsDeleteMessage } from './types'
import { useNavigate } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { columns, mapDispatchToProps, mapStateToProps } from './maps'
import { connect, useDispatch } from 'react-redux'
import { IconMenu } from '../../widgets/icon-menu'
import news, { clearEditableNews } from '../../app/store/slices/news'
import { SearchBar } from '../../widgets/search-bar'
import { SearchPanel } from '../../widgets/search-bar/SearchPanel'
import { type TSearchQuery } from '../../widgets/search-bar/types'
import { searchFilter } from '../../widgets/search-bar/search-service'
import { clearErrorMessage, clearInfoMessage, showErrorMessage, showInfoMessage } from 'app/store/slices/alerts'
import { loadNewsSaga } from 'app/store/sagas/root'

const newsManipulationService = NewsManipulationService()

export const NewsListPage: any = connect(mapStateToProps, mapDispatchToProps)(function ({
  setEditableNews,
  allNews,
  selectedAllNews,
  setAllNews,
  setSelectedAllNews
}: any) {
  const [localAllNews, setLocalAllNews] = useState(allNews)
  const [searchQuery, setSearchQuery] = useState<TSearchQuery[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /**
     * @todo: Выполнить загрузку данных в редакс, с целью обхода множественного запроса всех данных
     */

  const loadWholeAllNews = async () => {
    const allNews = await newsManipulationService.takeWholeAllNews()
    if (allNews.errorMessage) {
      dispatch(showErrorMessage(allNews.errorMessage))
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000); return
    }
    setAllNews(allNews.allNews || [])
  }

  useEffect(() => {
    setLocalAllNews(searchFilter(allNews, searchQuery))
  }, [searchQuery, allNews])

  useEffect(() => {
    loadNewsSaga()
    redirectIfNoRefreshToken(navigate)
  }, [])

  const deleteNews = useCallback(async () => {
    const deleteArrayNews = allNews.filter((news: TNews) => selectedAllNews.includes(news.id!))
    if (deleteArrayNews.length === 0) {
      return
    }

    const clonedSelected = [...deleteArrayNews]
    const interval = setInterval(async () => {
      if (clonedSelected.length === 0) {
        clearInterval(interval)
        await loadWholeAllNews(); return
      }

      const targetNews: TNews = clonedSelected.pop()
      setSelectedAllNews([])
      const newsDeleteMessage: TNewsDeleteMessage = await newsManipulationService.removeNews(targetNews.id || '')
      if (newsDeleteMessage.errorMessage) {
        dispatch(showErrorMessage(newsDeleteMessage.errorMessage))
        setTimeout(() => { dispatch(clearErrorMessage()) }, 5000); return
      }
      dispatch(showInfoMessage(`Новость ${targetNews.headers1} удалена`))
      setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
    }, 1000)
  }, [selectedAllNews, allNews])

  const onEditObject = useCallback((newNewsData: any) => {
    const newNews = [...allNews]
    newNews[newNewsData.keyId] = newNewsData
    delete newNews[newNewsData.keyId].keyId
    setAllNews(newNews)
  }, [allNews, setAllNews])

  const onNewsRowSave = useCallback(async (allNewsKey: number) => {
    const { errorMessage, status }: any = await newsManipulationService.updateNews(allNews[allNewsKey])

    if (errorMessage) {
      dispatch(showErrorMessage(errorMessage))
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000); return
    }
    dispatch(showInfoMessage(`Новость ${allNews[allNewsKey].id} успешно обновлена`))
    setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
    await loadWholeAllNews()
  }, [allNews])

  const onSearchQueryChange = useCallback((query: TSearchQuery[]) => {
    setSearchQuery(query)
  }, [])

  const onSelectionChange = (selection: string[]) => 
    setSelectedAllNews(selection)

  const onRowDblClick = (matchId: any) => {
    const target = allNews.find((el: any) => el.id === matchId)
    if (!target) {
      return
    }
    dispatch(setEditableNews(target))
    navigate(`/news/create?regime=update&id=${target.id}`)
  }

  const onCreateCLick = () => {
    dispatch(clearEditableNews())
    navigate('/news/create')
  }

  return <div className={styles.NewsPage}>
        <AdminMenu />
        <div className={styles.ListContainer}>
            <div className={styles.TableContainer}>
                <IconMenu
                    onSearchClick={() => {}}
                    onCreateClick={onCreateCLick}
                    onDeleteClick={deleteNews}
                />
                <SearchPanel
                    columns={columns.filter(col => col.field !== 'banner')}
                    onQueryChange={onSearchQueryChange}
                />
                <DataGrid
                    rows={localAllNews}
                    columns={columns}
                    onRowChange={(row: any[]) => { onEditObject(row) }}
                    onRowSave={onNewsRowSave}
                    onSelectionChange={onSelectionChange}
                    controls={true}
                    onRowDblClick={onRowDblClick}
                />
            </div>
        </div>

    </div>
})
