export enum EAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum EColumnType {
  number,
  string,
  // date,
  flag,
  image,
}

export interface TColumns {
  field: string
  headerName?: string
  width?: string
  editable?: boolean
  align?: EAlign
  type: EColumnType
}

export type TInternalRows = Record<string, any[]>

export interface IDataGridArgs {
  rows: any[]
  columns: TColumns[]
  onSelectionChange?: (selection: string[]) => void
  onRowChange?: (row: any[]) => void
  onRowDelete?: (row: any[]) => void
  onRowSave?: (key: number) => void
  controls?: boolean
  editable?: boolean
  sort?: boolean
  onRowDblClick?: (selection: string) => void
  className?: string
}

export type TActiveIndices = [number, number]
