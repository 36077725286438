import { createSlice } from '@reduxjs/toolkit'

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState: {
    tournaments: [],
    selectedTournaments: []
  },
  reducers: {
    setTournaments: (state, action) =>
      ({
        ...state,
        tournaments: action.payload
      }),
    setSelectedTournaments: (state, action) => ({
      ...state,
      selectedTournaments: action.payload
    })
  }
})

export const {
  setTournaments,
  setSelectedTournaments
} = tournamentsSlice.actions
export default tournamentsSlice.reducer
