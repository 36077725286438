import { createSlice } from '@reduxjs/toolkit'

export const playersSlice = createSlice({
  name: 'players',
  initialState: {
    players: [],
    selectedPlayers: []
  },
  reducers: {
    setPlayers: (state, action) =>
      ({
        ...state,
        players: action.payload
      }),
    setSelectedPlayers: (state, action) => ({
      ...state,
      selectedPlayers: action.payload
    })
  }
})

export const {
  setPlayers,
  setSelectedPlayers
} = playersSlice.actions
export default playersSlice.reducer
