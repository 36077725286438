import { RootState } from "../../../app/store";
import { TPlayer } from "../../../pages/PlayersPage/types";
import { DataGrid } from "../../data-grid";
import { connect, useDispatch } from 'react-redux'
import { EAlign, EColumnType, TColumns } from "../../data-grid/types";
import { CatchingPokemonSharp } from "@mui/icons-material";
import { TMatch } from "../../../pages/TournamentsPage/types";
import { setActivePlayers, setInternalPlayers, setTours, updateTeam } from "../../../app/store/slices/editableTournament";
import { useMemo, useState } from "react";

const cols: TColumns[] = [
    {
      field: 'id',
      headerName: '',
      width: '30px',
      editable: false,
      align: EAlign.left,
      type: EColumnType.string
    },
    {
      field: 'country',
      headerName: 'Страна',
      width: '30px',
      editable: false,
      align: EAlign.left,
      type: EColumnType.flag
    },
    {
      field: 'name',
      headerName: 'Имя игрока',
      width: '300px',
      editable: false,
      align: EAlign.left,
      type: EColumnType.string
    }
  ]

type TPlayerListArgs = {
    players: TPlayer[],
    activePlayers: TPlayer[],
    tours: TMatch[][] | undefined,
    selectedTourId: number | undefined,
    selectedMatchId: number | undefined,
    selectedTeamId: number | undefined,
}

const mapStateToProps = (state: RootState) => ({
    players: state.editableTournamentSlice.internalPlayers,
    activePlayers: state.editableTournamentSlice.activePlayers,
    tours: state.editableTournamentSlice.tours,
    selectedTourId: state.editableTournamentSlice.selectableParameters.tourId,
    selectedMatchId: state.editableTournamentSlice.selectableParameters.matchId,
    selectedTeamId: state.editableTournamentSlice.selectableParameters.teamId,
})

export const PlayersList = connect(mapStateToProps)(({
    players,
    activePlayers,
    tours,
    selectedTourId,
    selectedMatchId,
    selectedTeamId,
}: TPlayerListArgs) => {
    const [rows, setRows] = useState<any>([])
    const dispatch = useDispatch()

    const appendPlayerToMatch = (playerId: any) => {
        const filteredTournamentPlayers = players.filter(
            (p: TPlayer) => p.id !== undefined && playerId !== p.id
            )

        const choosenPlayerId = players.findIndex((p: TPlayer) => ((p._id !== undefined && playerId === p._id) || (p.id !== undefined && playerId === p.id)))

        const targetPlayer = players[choosenPlayerId]

        // /**
        //  * Проверяем что индексы выделения вообще существуют
        //  */
        // if(selectedTourId === undefined || selectedMatchId === undefined || selectedTeamId === undefined){
        //     return
        // }

        // /**
        //  * Проверяем что и правда выбрана хоть какая-то карточка
        //  */
        // if(selectedTourId === -1 || selectedMatchId === -1 || selectedTeamId === -1){
        //     return
        // }

        // /**
        //  * Проверяем что вообще существует турнир
        //  */
        // if(!tours){
        //     return
        // }

        // const deepCopyOfTours = JSON.parse(JSON.stringify(tours))

        // /**
        //  * Карточка выбрана. Значит может добавлять игрока или команду к этой карточке
        //  */

        // if(selectedTeamId === 0){
        //     deepCopyOfTours[selectedTourId][selectedMatchId].team1Names.forEach((player: TPlayer) => {
        //         filteredTournamentPlayers.push(player)
        //     })
        //     deepCopyOfTours[selectedTourId][selectedMatchId].team1Names = [targetPlayer]
            
        // }

        // if(selectedTeamId === 1){
        //     deepCopyOfTours[selectedTourId][selectedMatchId].team2Names.forEach((player: TPlayer) => {
        //         filteredTournamentPlayers.push(player)
        //     })
        //     deepCopyOfTours[selectedTourId][selectedMatchId].team2Names = [targetPlayer]
        // }
        dispatch(updateTeam({
            players: [targetPlayer],
            teamId: selectedTeamId,
        }))


        // dispatch(setTours(deepCopyOfTours))
        // dispatch(setInternalPlayers(filteredTournamentPlayers))
        // dispatch(setActivePlayers([...activePlayers, targetPlayer]))
        
    }

    if(players.length === 0){
        return <div>Список свободных игроков турнира пуст</div>
    }

    const nextRows = async (page: number, perPageCount: number) => {
        const newPlayers = players.slice(page * perPageCount, (page * perPageCount) + perPageCount)
        setRows(newPlayers)
        return newPlayers
    }

    return <DataGrid
        nextRows={nextRows}
        count={players.map((p: TPlayer) => ({...p, id: p.id || p._id})).length}
        rows={rows}
        columns={cols}
        controls={true}
        onRowDblClick={appendPlayerToMatch} />
})
