import { type TTournament, type TTournamentCreationResponse, type TTournamentListResponse } from '../../../pages/TournamentsPage/types'

const PLAYER_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/tournament`

export function TournamentManipulationService () {
  const createTournament = async (tournament: TTournament) => {
    const fetchRes = await fetch(PLAYER_URL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tournament)
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        tournament: null
      }
    }

    const response: TTournamentCreationResponse = await fetchRes.json()

    if (fetchRes.status === 406) {
      return {
        errorMessage: response.message || 'Несоответствие формата данных. Обратитесь к разработчикам',
        tournament: null
      }
    }

    return {
      errorMessage: null,
      tournament: response.tournament
    }
  }

  const takeWholeTournaments = async () => {
    const fetchRes = await fetch(PLAYER_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        tournaments: null
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет ни одного турнира',
        tournaments: null
      }
    }

    const response: TTournamentListResponse = await fetchRes.json()

    return {
      errorMessage: null,
      tournaments: response.tournaments.reverse()
    }
  }

  const removeTournament = async (id: string) => {
    const fetchRes = await fetch(`${PLAYER_URL}/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого турнира',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      return {
        errorMessage: 'Не прошла валидация полей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неожиданная ошибка в процессе удаления пользователя. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  const updateTournament = async (itournament: TTournament) => {

    const tournament = {
      ...itournament,
      internalPlayers: itournament.internalPlayers.map((player: any) => {
        return {
           ...player,
           avatar: undefined,
        }
      })
    }

    const fetchRes = await fetch(`${PLAYER_URL}/${tournament.id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...tournament, id: undefined })
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого турнира',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      const responseJson = await fetchRes.json()
      return {
        errorMessage: 'Не прошла валидация полей: ' + responseJson.message,
        status: 'fail'
      }
    }

    if (fetchRes.status === 200 || fetchRes.status === 304) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неопознанная ошибка. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  const getTournament = async (tournamentId: string) => {
    const fetchRes = await fetch(`${PLAYER_URL}/${tournamentId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого турнира',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      const responseJson = await fetchRes.json()
      return {
        errorMessage: 'Не прошла валидация полей: ' + responseJson.message,
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok',
        tournament: (await fetchRes.json()).tournament,
      }
    }

    return {
      errorMessage: 'Неопознанная ошибка. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  return {
    createTournament,
    takeWholeTournaments,
    removeTournament,
    updateTournament,
    getTournament,
  }
}
