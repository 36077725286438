import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import {
  DataGrid
} from '../../widgets/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { TournamentManipulationService } from '../../shared/tournaments/services/tournament-manipulation-service'
import { type TTournament, type TTournamentDeleteMessage } from './types'

import { useNavigate } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { columns, mapDispatchToProps, mapStateToProps } from './maps'
import { connect, useDispatch } from 'react-redux'
import { IconMenu } from '../../widgets/icon-menu'
import { SearchPanel } from '../../widgets/search-bar/SearchPanel'
import { type TSearchQuery } from '../../widgets/search-bar/types'
import { searchFilter } from '../../widgets/search-bar/search-service'
import { clearEditableTournament, setEditableTournamentFields } from '../../app/store/slices/editableTournament'
import { IsAdmin } from '../../shared/checkUser'
import { loadTournamentsSaga } from 'app/store/sagas/root'
import { showErrorMessage, clearErrorMessage, clearInfoMessage, showInfoMessage } from 'app/store/slices/alerts'
import { setCountTournaments } from 'app/store/slices/tournaments'

const tournamentManipulationService = TournamentManipulationService()

export const TournamentsListPage: any = connect(mapStateToProps, mapDispatchToProps)(function ({
  count,
  tournaments,
  selectedTournaments,
  setTournaments,
  setSelectedTournaments
}: any) {
  const [localTournaments, setLocalTournaments] = useState(tournaments)
  const [searchQuery, setSearchQuery] = useState<TSearchQuery[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loadWholeTournaments = async () => {
    
    const tournaments = await tournamentManipulationService.takeWholeTournaments()

    if (tournaments.errorMessage) {
      dispatch(showErrorMessage(tournaments.errorMessage))
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000); return
    }
    setTournaments(tournaments.tournaments || [])
  }

  useEffect(() => {
    setLocalTournaments(searchFilter(tournaments, searchQuery))
  }, [searchQuery, tournaments])

  useEffect(() => {
    // loadTournamentsSaga()
    redirectIfNoRefreshToken(navigate)
  }, [])

  const deleteTournament = useCallback(async () => {
    const deleteArrayTour = tournaments?.filter((tournament: TTournament) => selectedTournaments.includes(tournament.id!))
    if (deleteArrayTour.length === 0) {
      return
    }

    const clonedSelected = [...deleteArrayTour]
    const interval = setInterval(async () => {
      if (clonedSelected.length === 0) {
        clearInterval(interval)
        await loadWholeTournaments(); return
      }

      const targetTournament: TTournament = clonedSelected.pop()
      setSelectedTournaments([])
      const tournamentDeleteMessage: TTournamentDeleteMessage = await tournamentManipulationService.removeTournament(targetTournament.id || '')
      if (tournamentDeleteMessage.errorMessage) {
        dispatch(showErrorMessage(tournamentDeleteMessage.errorMessage))
        setTimeout(() => { dispatch(clearErrorMessage()) }, 5000); return
      }
      dispatch(showInfoMessage(`Турнир ${targetTournament.name} удален`))
      setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
    }, 1000)
  }, [selectedTournaments, tournaments])

  const onEditObject = useCallback((newTournamentData: any) => {
    const newTournaments = [...tournaments]
    newTournaments[newTournamentData.keyId] = newTournamentData
    delete newTournaments[newTournamentData.keyId].keyId
    setTournaments(newTournaments)
  }, [tournaments, setTournaments])

  const onTournamentRowSave = useCallback(async (tournamentsKey: number) => {
    const { errorMessage, status }: any = await tournamentManipulationService.updateTournament(tournaments[tournamentsKey])

    if (errorMessage) {
      dispatch(showErrorMessage(errorMessage))
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000); return
    }
    dispatch(showInfoMessage(`Турнир ${tournaments[tournamentsKey].name} успешно обновлен`))
    setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
    await loadWholeTournaments()
  }, [tournaments])

  const onSearchQueryChange = useCallback(async (query: TSearchQuery[]) => {
    if (query[0].value !== '') {
      const {count} = await tournamentManipulationService.getCountTournamentsField(query[0].field, query[0].value)
      dispatch(setCountTournaments(count))
      const {tournaments} = await tournamentManipulationService.getTournamentsField(query[0].field, query[0].value)
      dispatch(setTournaments(tournaments || []))
    } else {
      const {count} = await tournamentManipulationService.getCountTournaments()
      dispatch(setCountTournaments(count))
    }
    setSearchQuery(query)
  }, [])

  const onSelectionChange = (selection: string[]) =>
    setSelectedTournaments(selection)

  const onRowDblClick = (target: any) => {
    // const target = tournaments.find((el: any) => el.id === tournamentId)
    if (!target) {
      return
    }
    // console.log('target: ', target)
    // dispatch(setEditableTournamentFields(target))
    navigate(`/tournaments/create?regime=update&id=${target}`)
  }

  const onCreateCLick = () => {
    dispatch(clearEditableTournament())
    navigate('/tournaments/create')
  }

  const nextRows = async (page: number, perPageCount: number) => {
    if (searchQuery.length !== 0 && searchQuery[0].value !== '') {
      return tournaments.slice(perPageCount * page, (perPageCount * page) + perPageCount)
    }

    setTournaments((await tournamentManipulationService.getTournaments(page, perPageCount))?.tournaments)
    
    return 
  }

  return <div className={styles.TournamentPage}>
        <AdminMenu />
        <div className={styles.ListContainer}>
            <div className={styles.TableContainer}>
                <IsAdmin>
                    <IconMenu
                        onSearchClick={() => {}}
                        onCreateClick={onCreateCLick}
                        onDeleteClick={deleteTournament}
                     />
                </IsAdmin>
                <SearchPanel
                    columns={columns.filter(col => col.field !== "date")}
                    onQueryChange={onSearchQueryChange}
                    className={styles.SearchPanel}
                />
                <DataGrid
                    nextRows={nextRows}
                    count={count}
                    rows={localTournaments}
                    columns={columns}
                    onRowChange={(row: any[]) => { onEditObject(row) }}
                    onRowSave={onTournamentRowSave}
                    onSelectionChange={onSelectionChange}
                    onRowDblClick={onRowDblClick}
                    controls={true}
                    className={styles.DataGrid}
                />
            </div>
        </div>

    </div>
})
