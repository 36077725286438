// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_TournamentPage__1WN3s{
    position: fixed;
    left: 0; top: 0; bottom: 0; right: 0;
    display: grid;
    grid-template-columns: 2fr 12fr;
    overflow: auto;
}
.styles_ContentContainer__vFkzc{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.styles_FormContainer__brqE0 {
    max-width: 1200px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.styles_ControlPanel__7u4Tf{
    width: 100%;
    display: flex;
    justify-content: start;
}
.styles_ControlPanel__7u4Tf > *{
    margin-left: 30px;
}
.styles_selectButton__nWilJ{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-left: 30px;
}
.styles_selectButton__nWilJ *{
    border: 1px solid black;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/TournamentsPage/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO,EAAE,MAAM,EAAE,SAAS,EAAE,QAAQ;IACpC,aAAa;IACb,+BAA+B;IAC/B,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".TournamentPage{\n    position: fixed;\n    left: 0; top: 0; bottom: 0; right: 0;\n    display: grid;\n    grid-template-columns: 2fr 12fr;\n    overflow: auto;\n}\n.ContentContainer{\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n.FormContainer {\n    max-width: 1200px;\n    width: 80%;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n}\n.ControlPanel{\n    width: 100%;\n    display: flex;\n    justify-content: start;\n}\n.ControlPanel > *{\n    margin-left: 30px;\n}\n.selectButton{\n    margin-top: 30px;\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    padding-left: 30px;\n}\n.selectButton *{\n    border: 1px solid black;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TournamentPage": `styles_TournamentPage__1WN3s`,
	"ContentContainer": `styles_ContentContainer__vFkzc`,
	"FormContainer": `styles_FormContainer__brqE0`,
	"ControlPanel": `styles_ControlPanel__7u4Tf`,
	"selectButton": `styles_selectButton__nWilJ`
};
export default ___CSS_LOADER_EXPORT___;
