import { createSlice } from '@reduxjs/toolkit'

export const operatorsSlice = createSlice({
  name: 'operators',
  initialState: {
    operators: [],
    selectedOperators: []
  },
  reducers: {
    setOperators: (state, action) => {
      return {
        ...state,
        operators: action.payload,
      }
    },
    setSelectedOperators: (state, action) => ({
      ...state,
      selectedOperators: action.payload
    })
  }
})

export const {
  setOperators,
  setSelectedOperators
} = operatorsSlice.actions
export default operatorsSlice.reducer
