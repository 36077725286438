import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { type TPlayer } from './types'
import { EColumnType, type TColumns } from '../../widgets/data-grid/types'
import tournaments, { setSelectedTournaments, setTournaments } from '../../app/store/slices/tournaments'

export const columns: TColumns[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: '15vmax',
    type: EColumnType.string
  },
  {
    field: 'name',
    headerName: 'Название турнира',
    width: '20vmax',
    type: EColumnType.string
  },
  {
    field: 'startDate',
    headerName: 'Дата начала',
    width: '10vmax',
    type: EColumnType.string
  },
  {
    field: 'c5ompleteDate',
    headerName: 'Дата завершения',
    width: '10vmax',
    type: EColumnType.string
  },
  {
    field: 'gamers',
    headerName: 'Тип игроков',
    width: '10vmax',
    type: EColumnType.string
  }
]

export function mapStateToProps (state: RootState) {
  return {
    tournaments: state.tournamentsSlice.tournaments,
    selectedTournaments: state.tournamentsSlice.selectedTournaments
  }
}

export function mapDispatchToProps (dispatch: ReturnType<typeof useDispatch>) {
  return {
    setTournaments: (tournaments: TPlayer[]) => dispatch(setTournaments(tournaments)),
    setSelectedTournaments: (tournaments: TPlayer[]) => dispatch(setSelectedTournaments(tournaments))
  }
}
