import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { setFileStores, setSelectedFileStores } from '../../app/store/slices/fileStore'
import { type TFileStore } from './types'
import { EColumnType, type TColumns } from '../../widgets/data-grid/types'

export const columns: TColumns[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: '180px',
    type: EColumnType.string
  },
  {
    field: 'name',
    headerName: 'Название документа',
    width: '160px',
    type: EColumnType.string
  }
]

export function mapStateToProps (state: RootState) {
  return {
    fileStore: state.fileStoresSlice.fileStores,
    selectedFileStore: state.fileStoresSlice.selectedFileStores
  }
}

export function mapDispatchToProps (dispatch: ReturnType<typeof useDispatch>) {
  return {
    setFileStores: (fileStores: TFileStore[]) => dispatch(setFileStores(fileStores)),
    setSelectedFileStores: (fileStores: TFileStore[]) => dispatch(setSelectedFileStores(fileStores))
  }
}
