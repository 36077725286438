import { type TNews, type TNewsCreationResponse, type TNewsListResponse } from '../../../pages/NewsPage/types'
import { Simulate } from 'react-dom/test-utils'
// import {input} from "@testing-library/user-event/event/input";

const NEWS_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/news`

export function NewsManipulationService () {
  const createNews = async (news: TNews) => {
    const fetchRes = await fetch(NEWS_URL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(news)
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        news: null
      }
    }

    const response: TNewsCreationResponse = await fetchRes.json()

    if (response.status === 406) {
      return {
        errorMessage: response.message || null,
        news: null
      }
    }

    return {
      errorMessage: null,
      news: response.news
    }
  }

  const takeWholeAllNews = async () => {
    const fetchRes = await fetch(NEWS_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        allNews: null
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет ни одного игрока',
        allNews: null
      }
    }

    const response: TNewsListResponse = await fetchRes.json()

    return {
      errorMessage: null,
      allNews: response.allNews
    }
  }

  const removeNews = async (id: string) => {
    const fetchRes = await fetch(`${NEWS_URL}/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого игрока',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      return {
        errorMessage: 'Не прошла валидация полей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неожиданная ошибка в процессе удаления пользователя. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  const updateNews = async (news: TNews) => {
    const fetchRes = await fetch(`${NEWS_URL}/${news.id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...news, id: undefined })
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такогй новости',
        status: 'fail'
      }
    }

    if (fetchRes.status === 304) {
      return {
        errorMessage: 'Нет изменений',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      const responseJson = await fetchRes.json()
      return {
        errorMessage: 'Не прошла валидация полей: ' + responseJson.message,
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неопознанная ошибка. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  const getNews = async (newsId: string) => {
    const fetchRes = await fetch(`${NEWS_URL}/${newsId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого турнира',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      const responseJson = await fetchRes.json()
      return {
        errorMessage: 'Не прошла валидация полей: ' + responseJson.message,
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok',
        news: (await fetchRes.json()).news,
      }
    }

    return {
      errorMessage: 'Неопознанная ошибка. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  return {
    createNews,
    takeWholeAllNews,
    removeNews,
    updateNews,
    getNews
  }
}
