import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { EColumnType, type TColumns } from '../../widgets/data-grid/types'
import { setOperators, setSelectedOperators } from '../../app/store/slices/operators'
import { TOperator } from '../../shared/operator/operator-manipulation-service/operator-manipulation-service'

export const columns: TColumns[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: '20vmax',
    type: EColumnType.string
  },
  {
    field: 'login',
    headerName: 'Логин',
    width: '30vmax',
    type: EColumnType.string
  },
  {
    field: 'role',
    headerName: 'роль',
    width: '30vmax',
    type: EColumnType.string
  },
]

export function mapStateToProps (state: RootState) {
  return {
    operators: state.operatorsSlice.operators,
    selectedPlayers: state.playerSlice.selectedPlayers
  }
}

export function mapDispatchToProps (dispatch: ReturnType<typeof useDispatch>) {
  return {
    setPlayers: (operators: TOperator[]) => dispatch(setOperators(operators)),
    setSelectedOperators: (operators: TOperator[]) => dispatch(setSelectedOperators(operators))
  }
}
