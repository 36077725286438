const MATCH_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/match`

export function MatchManipulationService () {
  const takeWholeMaches = async () => {
    const fetchRes = await fetch(MATCH_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав',
        matches: null
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет ни одного матча',
        matches: null
      }
    }

    const response: any = await fetchRes.json()

    return {
      errorMessage: null,
      matches: response.matches
    }
  }

  return {
    takeWholeMaches
  }
}
