import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { setAllNews, setEditableNews, setSelectedAllNews } from '../../app/store/slices/news'
import { type TNews } from './types'
import { EColumnType, type TColumns } from '../../widgets/data-grid/types'

export const columns: TColumns[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: '180px',
    type: EColumnType.string
  },
  {
    field: 'headers1',
    headerName: 'Заголовок 1',
    width: '160px',
    type: EColumnType.string
  },
  {
    field: 'headers2',
    headerName: 'Заголовок 2',
    width: '100px',
    type: EColumnType.string
  },
  {
    field: 'banner',
    headerName: 'Фон/баннер',
    width: '30px',
    type: EColumnType.image
  },
  {
    field: 'text',
    headerName: 'Содержание',
    width: '80px',
    type: EColumnType.string
  }
]

export function mapStateToProps (state: RootState) {
  return {
    allNews: state.newsSlice.allNews,
    selectedAllNews: state.newsSlice.selectedAllNews
  }
}

export function mapDispatchToProps (dispatch: ReturnType<typeof useDispatch>) {
  return {
    setEditableNews: (news: TNews) => dispatch(setEditableNews(news)),
    setAllNews: (allNews: TNews[]) => dispatch(setAllNews(allNews)),
    setSelectedAllNews: (allNews: TNews[]) => dispatch(setSelectedAllNews(allNews))
  }
}
