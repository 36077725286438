import './styles.css'
import { EAlign, EColumnType, TColumns } from '../data-grid/types'
import { DataGrid } from '../data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { type ITwoColumnsChooserArgs } from './types'
import { SearchBar } from '../search-bar'
import { searchFilter } from '../search-bar/search-service'
import { type TSearchQuery } from '../search-bar/types'
import { RootState } from '../../app/store'
import { connect, useDispatch } from 'react-redux'
import { setActivePlayers, setInternalPlayers } from '../../app/store/slices/editableTournament'
import { TPlayer } from '../../pages/PlayersPage/types'
import player from '../../app/store/slices/player'

const cols: TColumns[] = [
  {
    field: 'id',
    headerName: '',
    width: '30px',
    editable: false,
    align: EAlign.left,
    type: EColumnType.string
  },
  {
    field: 'country',
    headerName: 'Страна',
    width: '30px',
    editable: false,
    align: EAlign.left,
    type: EColumnType.flag
  },
  {
    field: 'name',
    headerName: 'Имя игрока',
    width: '300px',
    editable: false,
    align: EAlign.left,
    type: EColumnType.string
  }
]

const mapStateToProps = (state: RootState) => ({
  tournamentPlayers: state.editableTournamentSlice.internalPlayers,
  activePlayers: state.editableTournamentSlice.activePlayers,
  tours: state.editableTournamentSlice.tours,
  wholePlayers: state.playerSlice.players,
})

export const TwoColumnsChooser =  connect(mapStateToProps)(({ 
  tournamentPlayers,
  activePlayers,
  wholePlayers,
  tours,
 }: ITwoColumnsChooserArgs) => {
  
  const [searchQuery, setSearchQuery] = useState<TSearchQuery | null>(null)
  
  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(setInternalPlayers([...tournamentPlayers, ...activePlayers].map((player) => wholePlayers.find((wholePlayer) => wholePlayer.name === player.name))))
    dispatch(setInternalPlayers([...tournamentPlayers, ...activePlayers]))
  },[wholePlayers])

  const appendToInternalPlayers = useCallback((id: string) => {

    if(tournamentPlayers.find((p: TPlayer) => p.id === id)){
      return
    }

    const [ targetPlayer ] = wholePlayers.filter((player: TPlayer) => player.id !== undefined && player.id === id)
    dispatch(setInternalPlayers([...tournamentPlayers, targetPlayer]))
  }, [tournamentPlayers, activePlayers, wholePlayers])

  const removeFromInternalPlayers = useCallback((id: string) => {
    dispatch(setInternalPlayers(tournamentPlayers.filter((player: TPlayer) => player.id !== undefined && player.id !== id)))
    dispatch(setActivePlayers(activePlayers.filter((player: TPlayer) => player.id !== undefined && player.id !== id)))
    /**
     * Добавить реализацию алгоритма поиска и удаления игрока из турнирной сетки 
     * Добавить реализацию алгоритма запрета на удаление игрока из турнирной сетки, если он уже играет
     */
  }, [tournamentPlayers, activePlayers, wholePlayers])

  const selectionVariants = cols.map(c => ({
    value: c.field,
    label: c.headerName || '',
    type: EColumnType.string
  }))

  const onSearchClick = useCallback((res: TSearchQuery) => {
    if (!res.value || !res.field || !res.equivalence) {
      setSearchQuery(null)
    }
    setSearchQuery(res)
  }, [])

  return <div className='TwoColumnsChooser'>
        <div></div>
        <SearchBar selectionVariants={selectionVariants} onSearchClick={onSearchClick}/>
        <DataGrid
            rows={[...tournamentPlayers, ...activePlayers]}
            columns={cols}
            onRowDblClick={removeFromInternalPlayers}
            controls={true}
        />
        <DataGrid
            rows={searchQuery ? searchFilter(wholePlayers, [searchQuery]) : wholePlayers}
            columns={cols}
            onRowDblClick={appendToInternalPlayers}
            controls={true}
        />
    </div>
})
