import { TableCell, TableRow } from '@mui/material'
import { EditPanel } from '../EditPanel'
import { type SyntheticEvent, useCallback, useState } from 'react'
import styles from './styles.module.css'
import '../../../../shared/flags.css'
import { IconButton } from '../../../../entities/IconButton'
import SavingIcon from '../../../../icons/save.svg'
import { collectObjectFromColumnsAndRows } from '../../../../shared/procedures'
import { countryCodeEmoji } from 'country-code-emoji'
import { EColumnType } from '../../types'

const tableRowStyle: any = {
  position: 'relative',
  width: '100%',
  height: '300px'
}

const divEditContainerStyle: any = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '25% 25% 25%'
}

const editableFieldContainerStyle: any = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  marginLeft: '50px',
  alignItems: 'center'
}

const editableFieldLabelStyle: any = {
  minWidth: '90px'
}

const editableInputStyle: any = {
  border: 0,
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  width: '200px'
}

export function EditableRow ({
  keys,
  rowsList,
  rows,
  cols,
  ind,
  onEditRow,
  onDeleteRow,
  changeSelection,
  isSelected,
  onRowSave,
  canBeEdit,
  onRowDblClick
}: any) {
  const [editable, setEditable] = useState(false)

  const cells = keys.map((key: string, index: number) => {
    if (cols[index].type === EColumnType.image) {
      return <TableCell sx={{
        position: 'relative',
        width: cols[index].width,
        whiteSpace: 'nowrap',
        cursor: 'default'
      }} key={`${key}${ind}`}>
                <img className={styles.PreviewImage} src={rows[key][ind] || ''} alt={'nothing showed'} />
            </TableCell>
    }

    if (cols[index].type === EColumnType.flag) {
      return <TableCell sx={{
        position: 'relative',
        width: cols[index].width,
        whiteSpace: 'nowrap',
        cursor: 'default'
      }} key={`${key}${ind}`}>
                <div className={`react-tel-input flag ${rows[key][ind].toLowerCase()}`}></div>
            </TableCell>
    }

    return <TableCell sx={{
      position: 'relative',
      maxWidth: cols[index].width,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'default'
    }} key={`${key}${ind}`}>
            {rows[key][ind]}
        </TableCell>
  })

  const onEditHandler = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setEditable(!editable)
  }, [editable])

  const onDeleteHandler = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (onDeleteRow) {
      onDeleteRow(collectObjectFromColumnsAndRows(rows, cols, ind))
    }
  }, [onDeleteRow, rows, cols, ind])

  canBeEdit && cells.push(<TableCell key={`${ind}ctrl`}>
        <EditPanel
            onEdit={onEditHandler}
            onDelete={onDeleteHandler}
        />
    </TableCell>)

  return <><TableRow
        onClick={() => changeSelection(rows.id[ind])}
        key={rows.id[ind]}
        onDoubleClick={() => {
          onRowDblClick(rowsList[ind] || rows.id[ind])
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(200, 200, 200, 0.1)'
          },
          backgroundColor: isSelected ? 'rgba(200, 200, 200, 0.1)' : undefined
        }}>
        {
            cells
        }
    </TableRow>
        {editable && <TableRow sx={tableRowStyle}>
            <div style={divEditContainerStyle}>
            {keys.map((key: string, index: number) => {
              return <div style={editableFieldContainerStyle}><label style={editableFieldLabelStyle}>{key}</label>
                    <input
                        className={styles.NonBorderedOnSelected + ' ' + styles.EditableInput }
                        style={editableInputStyle}
                        type={['banner', 'avatar'].includes(key) ? 'file' : (cols[index].type === 0 ? 'number' : 'text')}
                        id={`${key}${ind}`}
                        key={`${key}${ind}`}
                        value={(typeof rows[key][ind] === 'string' && rows[key][ind]?.indexOf('base64') !== -1) ? undefined : (rows[key][ind])}
                        onChange={(e) => {
                          const editableObject = collectObjectFromColumnsAndRows(rows, cols, ind)

                          if (e.target.files === null) {
                            Object.assign(editableObject, {
                              [key]: e.currentTarget.value,
                              keyId: ind
                            })
                            onEditRow(editableObject)
                          } else {
                            const reader = new FileReader()
                            reader.readAsDataURL(e.target.files[0])
                            reader.onloadend = () => {
                              Object.assign(editableObject, {
                                [key]: reader.result,
                                keyId: ind
                              })
                              onEditRow(editableObject)
                            }
                          }
                        }}
                    />
                </div>
            })}
                <IconButton icon={SavingIcon} className={styles.SavingIcon} onClick={() => onRowSave(ind)} />
            </div>
        </TableRow>}
    </>
}
