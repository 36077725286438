import { type ITableBodyArgs } from './types'
import { TableBody, TableCell, TableRow } from '@mui/material'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EditPanel } from '../EditPanel'
import { EditableRow } from '../EditableRow'
import { Simulate } from 'react-dom/test-utils'
import change = Simulate.change

export function TableRows ({ rows, cols, onSelectionChange, onEditRow, onDeleteRow, onRowSave, editable, onRowDblClick }: ITableBodyArgs) {
  const [selection, setSelection] = useState<string[]>([])

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selection)
    }
  }, [selection])

  const changeSelection = useCallback((ind: string) => {
    if (selection.includes(ind)) {
      setSelection(selection.filter(selected => selected !== ind)); return
    }
    setSelection([...selection, ind])
  }, [rows, onSelectionChange, selection])

  const isSelected = useCallback((id: string) => selection.includes(id), [selection])

  const keys = Object.keys(rows)

  if (!keys.includes('id')) {
    // throw new Error('Each row must have ID field')
    return <></>
  }

  const rowsJsx = Array.from(Array(rows.id.length)).map((_, ind) => {
    return <EditableRow
            keys={keys}
            rows={rows}
            cols={cols}
            ind={ind}
            key={rows.id[ind]}
            onEditRow={onEditRow}
            onDeleteRow={onDeleteRow}
            changeSelection={changeSelection}
            onRowSave={onRowSave}
            isSelected={isSelected(rows.id[ind])}
            canBeEdit={editable}
            onRowDblClick={onRowDblClick}
        />
  })

  return <TableBody>
        {rowsJsx}
    </TableBody>
}
