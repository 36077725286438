import { type TPlayer, type TPlayerCreationResponse, type TPlayerListResponse } from '../../../pages/PlayersPage/types'

const PLAYER_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/player`

export function PlayerManipulationService () {
  const createPlayer = async (player: TPlayer) => {
    const fetchRes = await fetch(PLAYER_URL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(player)
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        player: null
      }
    }

    const response: TPlayerCreationResponse = await fetchRes.json()

    if (response.status === 406) {
      return {
        errorMessage: response.message || null,
        player: null
      }
    }

    return {
      errorMessage: null,
      player: response.player
    }
  }

  const takeWholePlayers = async () => {
    const fetchRes = await fetch(PLAYER_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для создания пользователей',
        players: null
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет ни одного игрока',
        players: null
      }
    }

    const response: TPlayerListResponse = await fetchRes.json()

    return {
      errorMessage: null,
      players: response.players
    }
  }

  const removePlayer = async (id: string) => {
    const fetchRes = await fetch(`${PLAYER_URL}/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого игрока',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      return {
        errorMessage: 'Не прошла валидация полей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неожиданная ошибка в процессе удаления пользователя. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  const updatePlayer = async (player: TPlayer) => {
    const fetchRes = await fetch(`${PLAYER_URL}/${player.id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...player, id: undefined })
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для удаления пользователей',
        status: 'fail'
      }
    }

    if (fetchRes.status === 204) {
      return {
        errorMessage: 'В системе нет такого игрока',
        status: 'fail'
      }
    }

    if (fetchRes.status === 304) {
      return {
        errorMessage: 'Нет изменений',
        status: 'fail'
      }
    }

    if (fetchRes.status === 406) {
      const responseJson = await fetchRes.json()
      return {
        errorMessage: 'Не прошла валидация полей: ' + responseJson.message,
        status: 'fail'
      }
    }

    if (fetchRes.status === 200) {
      return {
        errorMessage: null,
        status: 'ok'
      }
    }

    return {
      errorMessage: 'Неопознанная ошибка. Обратитесь к разработчикам',
      status: 'fail'
    }
  }

  return {
    createPlayer,
    takeWholePlayers,
    removePlayer,
    updatePlayer
  }
}
