import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import {
  DataGrid
} from '../../widgets/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { PlayerManipulationService } from '../../shared/player/services/player-manipulation-service'
import { type TPlayer } from './types'
import { useNavigate } from 'react-router-dom'
import { mapDispatchToProps, mapStateToProps } from './maps'
import { connect, useSelector } from 'react-redux'
import { IconMenu } from '../../widgets/icon-menu'
import { SearchPanel } from '../../widgets/search-bar/SearchPanel'
import { type TSearchQuery } from '../../widgets/search-bar/types'
import { RootState } from '../../app/store'
import { EColumnType, TColumns } from '../../widgets/data-grid/types'
import { searchFilter } from '../../widgets/search-bar/search-service'
import { columns } from './maps';
import { TOperator } from '../../shared/operator/operator-manipulation-service/operator-manipulation-service'
import { loadOperatorsList } from 'app/store/sagas/root'



const playerManipulationService = PlayerManipulationService()

export const OperatoresListPage: any = connect(mapStateToProps, mapDispatchToProps)(function ({
  operators = [],
  setSelectedOperators,
}: any) {
  const [errorMessage, setErrorMessage] = useState('')
  const [searchQuery, setSearchQuery] = useState<TSearchQuery[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    loadOperatorsList()
  }, [])

  /**
   * TODO: Edit operator
   * TODO: Remove operator
   */

  const onSearchQueryChange = useCallback((query: TSearchQuery[]) => {
    setSearchQuery(query)
  }, [])

  const onSelectionChange = (selection: string[]) => setSelectedOperators(operators?.filter((operator: TOperator) => selection.includes(operator.id)) || [])

  return <div className={styles.PlayerPage}>
        <AdminMenu />
        <div className={styles.ListContainer}>
            <div className={styles.TableContainer}>
                <IconMenu
                    onSearchClick={() => {}}
                    onCreateClick={() => { navigate('/operatores/create') }}
                />
                <SearchPanel
                    columns={columns}
                    onQueryChange={onSearchQueryChange}
                />
                {<DataGrid
                    className={styles.DataGrid}
                    rows={searchFilter(operators, searchQuery)}
                    columns={columns}
                    onSelectionChange={onSelectionChange}
                    controls={true}
                    editable={false}
                /> }
            </div>
        </div>

    </div>
})
