import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import {
  DataGrid
} from '../../widgets/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { PlayerManipulationService } from '../../shared/player/services/player-manipulation-service'
import { type TPlayer, type TPlayerDeleteMessage } from './types'
import { useNavigate } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { columns, mapDispatchToProps, mapStateToProps } from './maps'
import { connect, useDispatch } from 'react-redux'
import { IconMenu } from '../../widgets/icon-menu'
import { SearchPanel } from '../../widgets/search-bar/SearchPanel'
import { type TSearchQuery } from '../../widgets/search-bar/types'
import { searchFilter } from '../../widgets/search-bar/search-service'
import { showErrorMessage, showInfoMessage, clearErrorMessage, clearInfoMessage } from 'app/store/slices/alerts'

const playerManipulationService = PlayerManipulationService()

export const PlayersListPage: any = connect(mapStateToProps, mapDispatchToProps)(function ({
  players,
  selectedPlayers,
  setPlayers,
  setSelectedPlayers
}: any) {
  const [errorMessage, setErrorMessage] = useState('')
  const [localPlayers, setLocalPlayers] = useState(players)
  const [searchQuery, setSearchQuery] = useState<TSearchQuery[]>([])
  const [selection, setSelection] = useState<any>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  /**
     * @todo: Выполнить загрузку данных в редакс, с целью обхода множественного запроса всех данных
     */

  const loadWholePlayers = async () => {
    const players = await playerManipulationService.takeWholePlayers()
    if (players.errorMessage) {
      setErrorMessage(players.errorMessage); return
    }
    setPlayers(players.players || [])
  }

  useEffect(() => {
    setLocalPlayers(searchFilter(players, searchQuery))
  }, [searchQuery, players])

  useEffect(() => {
    redirectIfNoRefreshToken(navigate)
    loadWholePlayers()
  }, [])

  const deletePlayer = useCallback(async () => {
    console.log(selection)
    if (selection.length === 0) {
      return
    }

    const onDeleteMessage = `Игроки ${selection.join(', ')} удалены`
    let onError = false
    await Promise.all(selection.map(async (pid: string) => {
        const playerDeleteMessage: TPlayerDeleteMessage = await playerManipulationService.removePlayer(pid)
        if (playerDeleteMessage.errorMessage) {
          setTimeout(() => { setErrorMessage('') }, 5000)
          dispatch(showErrorMessage(playerDeleteMessage.errorMessage));
          setTimeout(() => dispatch(clearErrorMessage()), 5000)
          onError = true
          return
        }
    })) 

    if(onError){
      loadWholePlayers()
      return
    }

    dispatch(showInfoMessage(onDeleteMessage))
    setTimeout(() => dispatch(clearInfoMessage()), 5000)
    loadWholePlayers() 
    return

    const clonedSelected = [...selectedPlayers]
    const interval = setInterval(async () => {
      if (clonedSelected.length === 0) {
        clearInterval(interval)
        await loadWholePlayers(); return
      }

      const targetPlayer: TPlayer = clonedSelected.pop()
      setSelectedPlayers([])
      const playerDeleteMessage: TPlayerDeleteMessage = await playerManipulationService.removePlayer(targetPlayer.id || '')
      if (playerDeleteMessage.errorMessage) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        setErrorMessage(playerDeleteMessage.errorMessage); return
      }
      setErrorMessage(`Игрок ${targetPlayer.id} удален`)
    }, 1000)
  }, [selection])

  const onEditObject = useCallback((newPlayerData: any) => {
    const newPlayers = [...players]
    newPlayers[newPlayerData.keyId] = newPlayerData
    delete newPlayers[newPlayerData.keyId].keyId
    setPlayers(newPlayers)
  }, [players, setPlayers])

  function checkBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return true;
    } else {
      var k = 1024;
      var dm = decimals < 0 ? 0 : decimals;
      var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      var newBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
      if (i > 0 && newBytes > 700) {
        setTimeout(() => { dispatch(clearErrorMessage()) }, 5000)
        dispatch(showErrorMessage(`Размер файла: ${newBytes + ' ' + sizes[i]}; больше допустимого: 700 КБ`));
        return false
      }
      return true;
    }
  }

  const onPlayerRowSave = useCallback(async (playersKey: number) => {
    const playerName = players[playersKey].name
    const playerСountry = players[playersKey].country

    const sizePhoto = new Blob([players[playersKey].avatar]).size
    if(!checkBytes(sizePhoto)) {
      return
    }

    const doubleSpaceCondition = /  /
    const reg = /^[A-Z]{2}$/

    if(!reg.test(playerСountry)){
      dispatch(showErrorMessage('Только двубуквенное обозначение большими латинскими буквами'));
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000)
      return
    }

    if(doubleSpaceCondition.test(playerName)){
      dispatch(showErrorMessage('В имени не должно быть двойных пробелов'));
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000)
      return
    }

    const { errorMessage, status }: any = await playerManipulationService.updatePlayer({...players[playersKey], name: playerName.trim()})

    if (errorMessage) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      dispatch(showErrorMessage(errorMessage));
      setTimeout(() => { dispatch(clearErrorMessage()) }, 5000)
      return
    }

    dispatch(showInfoMessage(`Пользователь ${players[playersKey].id} успешно обновлен`));
    setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
    await loadWholePlayers()
  }, [players])

  const onSearchQueryChange = useCallback((query: TSearchQuery[]) => {
    setSearchQuery(query)
  }, [])

  const onSelectionChange = (selection: string[]) => { 
    return setSelection(selection)
    // return setSelectedPlayers(players?.filter((player: TPlayer) => selection.includes(player.id)) || []) 
  }

  const onDeletePlayer = async (target: TPlayer) => {
    if(!target?.id){ 
      return
    }
    const playerDeleteMessage: TPlayerDeleteMessage = await playerManipulationService.removePlayer(target.id)
    if (playerDeleteMessage.errorMessage) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        dispatch(showErrorMessage(playerDeleteMessage.errorMessage));
        setTimeout(() => dispatch(clearErrorMessage()), 5000)
        return
    }
    dispatch(showInfoMessage(`Игрок ${target.id} удален`))
    setTimeout(() => dispatch(clearInfoMessage()), 5000)
    loadWholePlayers()
  }

  return <div className={styles.PlayerPage}>
    <AdminMenu />
    <div className={styles.ListContainer}>
      <div className={styles.TableContainer}>
        <IconMenu
          onSearchClick={() => { }}
          onCreateClick={() => { navigate('/players/create') }}
          onDeleteClick={deletePlayer}
        />
        <SearchPanel
          columns={columns.filter(col => col.field !== 'avatar' && col.field !== 'birthDate')}
          onQueryChange={onSearchQueryChange}
        />
        <DataGrid
          rows={localPlayers}
          columns={columns}
          onRowChange={(row: any[]) => { onEditObject(row) }}
          onRowSave={onPlayerRowSave}
          onSelectionChange={onSelectionChange}
          onRowDelete={onDeletePlayer}
          controls={true}
          editable={true}
        />
      </div>
    </div>
  </div>
})
